import axios from 'axios'
import router from '../router'
axios.defaults.withCredentials = true
const token = localStorage.getItem('access_token');
//console.log(token);

var dev_type = process.env.VUE_APP_Enviroment;
//console.log(process.env);


var base_url = localStorage.getItem('base_url');
if(base_url == null){
    location.reload();
}
var apiLink = (dev_type == 'local') ? process.env.VUE_APP_Local_Api : process.env.VUE_APP_Live_Api;
apiLink = base_url+'/api/community/'

axios.defaults.baseURL = apiLink;

var logoutUrl = (dev_type == 'local') ? process.env.VUE_APP_Local_URL : process.env.VUE_APP_Live_URL;
logoutUrl = base_url;
if(token != '') {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json'
};
axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS,Some';

export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:{},
        members:{},
        communityBlockedUser: false
    },
    getters:{
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        },
        members(state){
            return state.members
        },
        community(state){
            return state.communityBlockedUser
        }
    },
    mutations:{
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
            state.user = value
        },
        SET_MEMEBERS (state, value) {
            state.members = value
        },
        SET_COMMUNITY_USER (state, value) {
            state.communityBlockedUser = value
        }

    },
    actions:{
        
        login({commit},access){
            if(typeof access == 'undefined' || access == null){
                    access = localStorage.getItem('access_token');
            }
            return axios.get('/user',{
          headers: {
            'Authorization': `Bearer ${access}`,
            'Accept': 'application/json'
          },
        }).then(({data})=>{
                
                commit('SET_USER',data);
                commit('SET_AUTHENTICATED',true);
                var community = localStorage.getItem('community');

                localStorage.setItem('type',data.user_type);

                //router.push({name:'threads',params:{course_code:community,type:'all'}});
                window.location = 'threads/'+community+'/all';
            }).catch(({data})=>{
               
                
            })
        },
        logout({commit}){
            
            var user_type = localStorage.getItem('type');
            var apiLink = localStorage.getItem('base_url');
            localStorage.clear();
            // localStorage.removeItem('vuex');
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('chatstatus');
            // localStorage.removeItem('c_token');
            // localStorage.removeItem('roomId');
            // localStorage.removeItem('s_mid');
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false);
            user_type = (user_type == '2') ? 'client' : 'coach';
            
            console.log('here logout');
            window.location = apiLink+'/'+user_type
            //window.location = apiLink+'/home/logout';
           // window.location = this.apiLink+'/'+user_type;
        },
        getCommunityMember({commit}, {access_token, community_id}) {
            if(typeof access_token == 'undefined' || access_token == null){
                access_token = localStorage.getItem('access_token');
            }

            return axios.get(`/getCommunityUserStatus/${community_id}`, {
                headers: {
                    'Authorization': `Bearer ${access_token}`,
                    'Accept': 'application/json'
                },
            }).then(({data})=>{
                console.log('data = ', data.result.is_blocked)
                commit('SET_COMMUNITY_USER', data.result.is_blocked);
            })
            .catch(({data})=>{
                commit('SET_COMMUNITY_USER', 0);
            })

        }
    }
}